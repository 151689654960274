<template>
  <v-card rounded="0">
    <v-card rounded="0" color="#f3f2ed" class="pt-4">
      <v-container>
        <v-card>
          <v-container>
            <v-container>
              <h4 class="h35 text-center pb-2" style="color:#185454">Luanda (FUBU) Project</h4>
              <v-divider />
              <v-row>
                <v-col cols="12" lg="6">
                  <h5 class="h5 my-2">Our Hope and Angels Centre in Luanda is a daily haven for children left unprotected on the streets. At the Centre, these children are given two meals/day, clothes, shoes, and school supplies. They are taught how to read and write by full-time teachers employed by Hope and Angels. At the present time, our Centre is not recognized officially by Angola’s Department of Education. It is an immediate goal of the Foundation to receive accreditation of its academic programs.</h5>
                  <h5 class="h5 my-2"><b>Immediate Goals:</b></h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>$900.00 per month to cover operating costs</h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>purchase of electronic equipment to establish a computer classroom</h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>accreditation of academic programs by Angola’s Department of Education</h5>
                  <h5 class="h5 my-2"><b>Long-Term Goals:</b></h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>infrastructure improvements and expansion to accommodate additional children</h5>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-carousel
                    class="pa-0"
                    cycle
                    height="auto"
                    :show-arrows-on-hover="true"
                    :hide-delimiters="false"
                    delimiter-icon="mdi-minus"
                  >
                    <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      fade
                      eager
                    >
                      <v-img :src="item.src" eager/>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/luandaProject/Picture4.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture5.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture6.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture7.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture8.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture10.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture11.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture12.jpg')
        },
        {
          src: require('@/assets/images/luandaProject/Picture13.jpg')
        }
      ]
    }
  }
}
</script>