<template>
  <v-card rounded="0">
    <v-card rounded="0" color="#f3f2ed" class="pt-4">
      <v-container>
        <v-card>
          <v-container>
            <v-container>
              <h4 class="h35 text-center pb-2" style="color:#185454">Mupa Project</h4>
              <v-divider />
              <v-row>
                <v-col cols="12" lg="6">
                  <h5 class="h5 my-2">Hope and Angels Foundation cares for severely impoverished and vulnerable children at The Mission of Mupa Orphanage/Boarding School in the Province of Cunene.  The Foundation provides the children with food (2 meals/day), clothing, shoes, shelter and education (Grades 1-9).  Many of these children have absolutely no family support. Without the assistance of Hope and Angels, they would be completely destitute and their lives would be bleak.</h5>
                  <h5 class="h5 my-2"><b>Immediate Goals:</b></h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>$700.00 per month to cover living costs</h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>further development of agricultural and farming activities</h5>
                  <h5 class="h5 my-2"><b>Long-Term Goals:</b></h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>install fresh water well on site</h5>
                  <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>renovate and expand current facility in order to care for additional children</h5>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-carousel
                    class="pa-0"
                    cycle
                    height="auto"
                    :show-arrows-on-hover="true"
                    :hide-delimiters="false"
                    delimiter-icon="mdi-minus"
                  >
                    <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      fade
                      eager
                    >
                      <v-img :src="item.src" eager/>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/mupaProject/Picture14.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture15.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture16.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture17.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture18.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture19.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture20.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture21.png')
        },
        {
          src: require('@/assets/images/mupaProject/Picture22.png')
        }
      ]
    }
  }
}
</script>