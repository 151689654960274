<template>
  <div>
    <v-parallax
      dark
      :src="imgSrc"
      height="400"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h2' : 'h3']">
            PROJECTS
          </h1>
          <v-container class="py-0">
            <hr>
          </v-container>
        </v-row>
      </v-overlay>
    </v-parallax>
    <v-card rounded="0" color="grey darken-3">
      <v-tabs v-model="tab" right background-color="grey darken-3" color="white">
        <v-tab href="#tab-Overview" style="color:white">
          Overview
        </v-tab>
        <v-tab href="#tab-MupaProject" style="color:white">
          Mupa Project
        </v-tab>
        <v-tab href="#tab-LuandaProject" style="color:white">
          Luanda (FUBU) Project
        </v-tab>
        <v-tab href="#tab-ClaudiaProject" style="color:white">
          Claudia Project
        </v-tab>
        <v-tab href="#tab-Scholarship" style="color:white">
          Academic Scholarships
        </v-tab>
        <v-tab href="#tab-OndjivaProject" style="color:white">
          Ondjiva Project
        </v-tab>

        <v-tab-item value="tab-Overview">
          <v-card flat rounded="0">
            <v-card-text>
              <ProjectsContent />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-MupaProject">
          <v-card flat rounded="0">
            <v-card-text>
              <MupaProject />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-LuandaProject">
          <v-card flat rounded="0">
            <v-card-text>
              <LuandaProject />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-ClaudiaProject">
          <v-card flat rounded="0">
            <v-card-text>
              <ClaudiaProject />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-Scholarship">
          <v-card flat rounded="0">
            <v-card-text>
              <ScholarshipProject />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-OndjivaProject">
          <v-card flat rounded="0">
            <v-card-text>
              <OndjivaProject />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ProjectsContent from '@/components/hope/ProjectsContent.vue'
import MupaProject from '@/components/hope/MupaProject.vue'
import LuandaProject from '@/components/hope/LuandaProject.vue'
import ClaudiaProject from '@/components/hope/ClaudiaProject.vue'
import ScholarshipProject from '@/components/hope/ScholarshipProject.vue'
import OndjivaProject from '@/components/hope/OndjivaProject.vue'

export default {
  components: {
    ProjectsContent,
    MupaProject,
    LuandaProject,
    ClaudiaProject,
    ScholarshipProject,
    OndjivaProject
  },
  data() {
    return {
      imgSrc: require('@/assets/images/IMG_1518.jpg')
    }
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Projects'
      }
    }
  }
}
</script>
